define('fund-figure-website/helpers/lnr-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lnrIcon = lnrIcon;
  function lnrIcon(params, hash) {
    let icon = hash.icon,
        stacked = hash.stacked,
        className = hash.classes,
        icon1 = hash.icon1,
        icon2 = hash.icon2,
        iconHTML = null;
    if (stacked) {
      iconHTML = `<span class="lnr-stacked"><i class="${icon1}"></i><i class="${icon2}"></i></span>`;
      return iconHTML;
    }
    if (icon) {
      iconHTML = '<span class="lnr lnr-' + icon + ' ' + className + '"></span>';
    } else {
      iconHTML = '<span class="lnr ' + params[0] + '"></span>';
    }
    return iconHTML;
  }

  exports.default = Ember.Helper.helper(lnrIcon);
});