define('fund-figure-website/controllers/documentation/docs/deposits/automate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    automateModel: { "vendor": null, "department": null },

    actions: {
      saveVendorInfo() {
        Ember.set(this, 'savingVendor', true);
        Ember.set(this, 'vendorSaved', null);
        let newVendorInfo = Ember.get(this, 'store').createRecord('collaborate', {
          vendor: Ember.get(this, 'automateModel.vendor'),
          department: Ember.get(this, 'automateModel.department')
        });
        setTimeout(() => {
          newVendorInfo.save().then(() => {
            Ember.set(this, 'savingVendor', false);
            Ember.set(this, 'vendorSaved', 'Thanks for giving us this info, fingers crossed they are into it!');
          }).catch(err => {
            console.log(err);
          });
        }, 1500);
      },
      addAnotherVendor() {
        Ember.set(this, 'automateModel.department', null);
        Ember.set(this, 'savingVendor', false);
        Ember.set(this, 'vendorSaved', false);
        setTimeout(() => {
          $("#municipalDepartment").focus();
        }, 500);
      }
    }
  });
});