define('fund-figure-website/controllers/home/index', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    connectedDeviceInfo: Ember.inject.service(),
    store: Ember.inject.service(),
    child1: 0,
    child2: 0,
    child3: 0,
    child4: 0,
    visualData: null,
    getVisualData: Ember.computed('redraw', {
      get() {
        return Ember.get(this, 'store').query('municipalAccount', {
          orderBy: 'municipalYear',
          equalTo: '-LZVi2Vo8j0hW7ApKDz7'
        }).then(res => {
          // set(this, 'visualData', res.toArray());
          return res.toArray();
        }).then(array => {
          return array.filter(a => {
            if (a.toJSON().parentAccount) {
              return false;
            } else {
              if (+a.toJSON().budget > 10000) {
                return true;
              } else {
                return false;
              }
            }
          });
        }).then(filterArray => {
          Ember.set(this, 'visualData', filterArray);
        }).catch(err => {
          console.log(err);
        });
      }
    }),
    sum: Ember.computed('child1', 'child2', 'child3', 'child4', {
      get() {
        let sum = Number(String(Ember.get(this, 'child1')).replace(/\$/g, "").replace(/,/g, "")) + Number(String(Ember.get(this, 'child2')).replace(/\$/g, "").replace(/,/g, "")) + Number(String(Ember.get(this, 'child3')).replace(/\$/g, "").replace(/,/g, "")) + Number(String(Ember.get(this, 'child4')).replace(/\$/g, "").replace(/,/g, ""));
        return (0, _formatMoney.default)(sum);
      }
    }),
    actions: {
      formatMoney(attrName, value) {
        let formatted = (0, _formatMoney.default)(value);
        Ember.set(this, attrName, formatted);
        // this.notifyPropertyChange(attrName);
      },
      nodeClicked(d) {
        let visualData = this.get('visualData');
        console.log(d.model.toJSON().subAccounts);
        if (d.model.toJSON().subAccounts.length > 1) {
          visualData.clear();
          // data.push({d});       
          // restart();
          d.model.get('subAccounts').forEach(sub => {
            let JSON = sub.toJSON();
            let object = {};
            console.log('sub', JSON);
            if (JSON.nickname) {
              object['accountName'] = JSON.nickname;
            } else {
              object['accountName'] = 'No Name';
            }
            object['model'] = sub;
            object['budget'] = JSON.budget.replace(/,/g, '');
            // subArray.push(object);
            visualData.push(object);
          });
          if (visualData.length == d.model.toJSON().subAccounts.length) {
            console.log('restart');
            visualData.push(d);
            this.notifyPropertyChange('visualData');
            // _this.set('data', subArray);
            // restart();
          }
        } else {
          return;
        }
      },
      canvasClicked(d) {
        console.log(d);
        this.notifyPropertyChange('redraw');
        // this.get('fiscalAccounts');
      }
    }
  });
});