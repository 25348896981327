define('fund-figure-website/components/documentation/docs-desktop-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'flex-row flex-grow1',
    // textSearch: null,
    // currentSearchItem: 0,
    // totalSearchItemsFound: 0,

    actions: {
      searchText() {
        Ember.get(this, 'searchText')();
      },
      delaySearch() {
        Ember.get(this, 'delaySearch')();
      },
      clearSearch() {
        Ember.get(this, 'clearSearch')();
      },
      nextSearch() {
        Ember.get(this, 'nextSearch')();
      },
      prevSearch() {
        Ember.get(this, 'prevSearch')();
      }
    }
  });
});