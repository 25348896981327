define('fund-figure-website/services/connected-device-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isDesktop: false,
    isMobile: false,
    isTv: false,
    getDevice: Ember.computed('navigator', {
      get() {
        console.log('checking device info');
        let ua = navigator.userAgent;
        console.log(ua);
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS|Phone/i.test(ua)) {
          //mobile device
          console.log('mobile device');
          Ember.set(this, 'isMobile', true);
          return 'mobile';
        } else if (/OS X|Windows|Linux/i.test(ua)) {
          //desktop
          console.log('desktop device');
          Ember.set(this, 'isDesktop', true);
          return 'desktop';
        } else if (/tvOS|xbox/i.test(ua)) {
          //tv platforms
          console.log('tv device');
          Ember.set(this, 'isTv', true);
          return 'tv';
        }
      }
    })
    // isMobile: computed('', {
    //   get() {
    //   	console.log('checking device info');
    //     let ua = navigator.userAgent;
    //     console.log(ua);
    //     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS|Phone/i.test(ua)) {
    //       //mobile device
    //       console.log('mobile device');
    //       return 'mobile';        
    //     }
    //     return false;
    //   }
    // }).property('init'),
    // isDesktop: computed('', {
    //   get() {
    //   	console.log('checking device info');
    //     let ua = navigator.userAgent;
    //     console.log(ua);
    //     if (/OS X|Windows|Linux/i.test(ua)) {
    //       //mobile device
    //       console.log('desktop device');
    //       return 'desktop';        
    //     }
    //     return false;
    //   }
    // }).property('init'),
    // isTv: computed('', {
    //   get() {
    //   	console.log('checking device info');
    //     let ua = navigator.userAgent;
    //     console.log(ua);
    //     if (/tvOS|xbox/i.test(ua)) {
    //       //mobile device
    //       console.log('TV device');
    //       return 'TV';        
    //     }
    //     return false;
    //   }
    // }).property('init'),
  });
});