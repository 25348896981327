define('fund-figure-website/controllers/documentation/api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    connectedDeviceInfo: Ember.inject.service(),
    queryParams: ['textSearch'],
    textSearch: null,
    currentSearchItem: 0,
    totalSearchItemsFound: 0,
    init() {
      this._super(...arguments);
      setTimeout(() => {
        let textSearch = Ember.get(this, 'textSearch');
        let _this = this;
        if (!textSearch) {
          _this.scrollToTerm(false);
          return;
        }
        var context = document.querySelector(".text-content");
        var instance = new Mark(context);
        instance.unmark().mark(textSearch, {
          'separateWordSearch': false,
          'done': function (counter) {
            Ember.set(_this, 'totalSearchItemsFound', counter);
            _this.scrollToTerm(true);
          }
        });
      }, 500);
    },
    scrollToTerm(performSearch) {
      if (!performSearch) {
        $('.space92').animate({ scrollTop: 0 }, 500);
        return;
      }
      let itemCurrentIndex = Ember.get(this, 'currentSearchItem'),
          nextItem = $('mark').eq(itemCurrentIndex);
      $('.mark-search').removeClass('mark-search');
      nextItem.addClass('mark-search');
      $('.space-stay92').animate({ scrollTop: (nextItem.position().top || 20) - 10 }, 500);
    },
    actions: {
      searchText() {
        let textSearch = Ember.get(this, 'textSearch');
        let _this = this;
        var context = document.querySelector(".text-content");
        var instance = new Mark(context);
        instance.unmark().mark(textSearch, {
          'separateWordSearch': false,
          'done': function (counter) {
            Ember.set(_this, 'totalSearchItemsFound', counter);
            _this.scrollToTerm(true);
          }
        });
      },
      delaySearch() {
        Ember.set(this, 'currentSearchItem', 0);
        setTimeout(() => {
          let textSearch = Ember.get(this, 'textSearch');
          let _this = this;
          if (!textSearch) {
            _this.scrollToTerm(false);
            return;
          }
          var context = document.querySelector(".text-content");
          var instance = new Mark(context);
          instance.unmark().mark(textSearch, {
            'separateWordSearch': false,
            'done': function (counter) {
              Ember.set(_this, 'totalSearchItemsFound', counter);
              _this.scrollToTerm(true);
            }
          });
        }, 500);
      },
      clearSearch() {
        Ember.set(this, 'textSearch', null);
        var context = document.querySelector(".text-content");
        var instance = new Mark(context);
        instance.unmark();
        Ember.set(this, 'currentSearchItem', 0);
        Ember.set(this, 'totalSearchItemsFound', 0);
      },
      nextSearch() {
        if (Ember.get(this, 'currentSearchItem') + 1 == Ember.get(this, 'totalSearchItemsFound')) {
          return;
        }
        this.incrementProperty('currentSearchItem');
        this.scrollToTerm(true);
      },
      prevSearch() {
        if (Ember.get(this, 'currentSearchItem') <= 0) {
          return;
        }
        this.decrementProperty('currentSearchItem');
        this.scrollToTerm(true);
      }
    }
  });
});