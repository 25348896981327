define('fund-figure-website/components/helper/toggle-property', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    click() {
      let property = this.get('property');
      this.toggleProperty(property);
    }
  });
});