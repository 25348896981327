define('fund-figure-website/routes/home/customers', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			return this.store.findAll('fundFigureCustomer');
		}
	});
});