define('fund-figure-website/models/municipal-account', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // stateAccountNumber: DS.belongsTo('account-number', { async: true, inverse: null }), //Link back to a list of account numbers and descriptions governed by state
    budget: _emberData.default.attr('string'), //A number derived by the town before vote
    appropriation: _emberData.default.attr('boolean'), //Expense account
    activated: _emberData.default.attr('boolean'), //Account is activated and used by town
    accountDesc: _emberData.default.attr('string'), //Municipalities Description of Account
    nickname: _emberData.default.attr('string'), //Name town can choose to give account
    accountNumber: _emberData.default.attr('string'), //Account number that town wants to give account
    parentAccount: _emberData.default.belongsTo('municipal-account', { async: true, inverse: null }),
    subAccounts: _emberData.default.hasMany('municipal-account', { async: true, inverse: null }),
    // municipalYear: DS.belongsTo('municipal-year', { async: true, inverse: null }), //Link back to year
    // municipalFund: DS.belongsTo('municipal-fund', { async: true, inverse: null }),
    // transactions: DS.hasMany('transaction', { async: true, inverse: null }),
    balance: _emberData.default.attr('number'),
    // municipalDepartment: DS.belongsTo('municipal-department'),
    netTransactions: _emberData.default.attr('number'), //Used for general reporting
    source: _emberData.default.attr('string'),
    warrant: _emberData.default.attr('boolean'), //If true account receives warrants
    uncollected: _emberData.default.attr('boolean'), //if true account receives warrant is is drawed down when money is received
    // function: DS.belongsTo('municipal-account-function'),
    // previousYear: DS.belongsTo('municipal-account', { async: true, inverse: null }),
    // municipality: DS.belongsTo('municipal-db', { async: true, inverse: null }), //Link back to town info for querying
    classification: _emberData.default.attr('string'), //What the account is for (Revenue, Expenses, Cash, Debt)
    transactDebit: _emberData.default.attr('boolean'), //Normal Account Balance is Debit (Debits increase balance)
    // transactCredit: DS.attr('boolean'), //Normal Account Balance is Credit (Credits increase balance)
    // stringAccount: null,
    warrantArticle: _emberData.default.attr('string'),
    // municipalArticle: DS.belongsTo('municipal-article'),
    stateName: _emberData.default.attr('string'),
    stateNumber: _emberData.default.attr('string'),
    searchField: Ember.computed('stateNumber', 'stateName', 'nickname', 'accountDesc', 'stateAccountNumber.searchField', {
      get() {
        return `${this.get('stateNumber')}${this.get('stateName')}${this.get('nickname')}${this.get('accountDesc')}${this.get('stateAccountNumber.searchField')}`;
      }
    })
  });
});