define('fund-figure-website/components/documentation/docs-mobile-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: '',

    actions: {
      searchText() {
        Ember.get(this, 'searchText')();
      },
      delaySearch() {
        Ember.get(this, 'delaySearch')();
      },
      clearSearch() {
        Ember.get(this, 'clearSearch')();
      },
      nextSearch() {
        Ember.get(this, 'nextSearch')();
      },
      prevSearch() {
        Ember.get(this, 'prevSearch')();
      }
    }
  });
});