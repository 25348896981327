define('fund-figure-website/routes/home', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    firebaseApp: Ember.inject.service(),
    beforeModel() {
      // this._super();
      return this.get("session").fetch().catch(function () {});
    },
    afterModel() {
      let session = Ember.get(this, 'session') || null,
          firebase = Ember.get(this, 'firebaseApp');
      console.log('session', session);
      if (!session.isAuthenticated) {
        firebase.auth().signInAnonymously().catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
        });

        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            // User is signed in.
            var isAnonymous = user.isAnonymous;
            var uid = user.uid;
            console.log('user signed in anonymously', uid);
          } else {
            console.log('user signed out');
          }
        });
      }
    }
  });
});