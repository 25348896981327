define('fund-figure-website/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    // Add your transitions here, like:
    //   this.transition(
    //     this.fromRoute('people.index'),
    //     this.toRoute('people.detail'),
    //     this.use('toLeft'),
    //     this.reverse('toRight')
    //   );
    this.transition(this.fromRoute('home.index'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('home.pricing'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.deposits'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.deposits.quickstart'), this.fromRoute('documentation.docs.deposits.automate'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.invoices'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.invoices.quickstart'), this.fromRoute('documentation.docs.invoices.recurring-invoices'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.journal'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.journal.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.ledger'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.ledger.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.budget'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.budget.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.payments'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.payments.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.vendor'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.vendor.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.vendor.vendor-dashboard'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.employees'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.employees.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.employees.employee-dashboard'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.reconciliation'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.reconciliation.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.reconciliation.linked-bank-reconciliation'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.reconciliation.reports'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.reports'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.reports.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.reports.state-reports'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.reports.town-reports'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.reports.visual-reports'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.settings'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('documentation.docs.settings.quickstart'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.settings.manage-users'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.settings.manage-funds'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.toRoute('documentation.docs.settings.manage-linked-bank-accounts'), this.use('toDown'), this.reverse('toUp'));

    //Website
    this.transition(this.hasClass('toDown'), this.toValue(true), this.use('toDown', { duration: 1000 }), this.reverse('toUp', { duration: 1000 }));
    this.transition(this.hasClass('toLeft'), this.toValue(true), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.hasClass('fade'), this.toValue(true), this.use('fade', { duration: 300 }), this.reverse('fade', { duration: 300 }));

    this.transition(this.hasClass('toRight'), this.toValue(true), this.use('toRight', { duration: 1000 }), this.reverse('toLeft', { duration: 1000 }));

    this.transition(this.hasClass('toRightLong'), this.toValue(true), this.use('toRight', { duration: 1000 }), this.reverse('toLeft', { duration: 1000 }));

    this.transition(this.hasClass('scale'), this.toValue(true), this.use('scale'), this.reverse('scale'));

    this.transition(this.hasClass('toUp'), this.toValue(true), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));
  };
});