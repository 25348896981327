define('fund-figure-website/router', ['exports', 'fund-figure-website/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('home', { path: '/' }, function () {
      this.route('about');
      this.route('pricing');
      this.route('customers');
      this.route('asset-management');
    });
    this.route('documentation', function () {
      this.route('api', function () {
        this.route('deposit', function () {
          this.route('create');
          this.route('item');
        });
        this.route('invoice', function () {
          this.route('item');
          this.route('create');
          this.route('update');
        });
      });
      this.route('docs', function () {
        this.route('deposits', function () {
          this.route('quickstart');
          this.route('automate');
        });
        this.route('invoices', function () {
          this.route('quickstart');
          this.route('recurring-invoices');
        });
        this.route('journal', function () {
          this.route('quickstart');
        });
        this.route('ledger', function () {
          this.route('quickstart');
          this.route('detailed-view');
        });
        this.route('budget', function () {
          this.route('quickstart');
        });
        this.route('payments', function () {
          this.route('quickstart');
          this.route('voiding-payments');
        });
        this.route('vendor', function () {
          this.route('quickstart');
          this.route('vendor-dashboard');
        });
        this.route('employees', function () {
          this.route('quickstart');
          this.route('employee-dashboard');
        });
        this.route('reconciliation', function () {
          this.route('quickstart');
          this.route('reports');
          this.route('linked-bank-reconciliation');
        });
        this.route('reports', function () {
          this.route('quickstart');
          this.route('state-reports');
          this.route('town-reports');
          this.route('visual-reports');
        });
        this.route('settings', function () {
          this.route('quickstart');
          this.route('manage-users');
          this.route('manage-funds');
          this.route('manage-linked-bank-accounts');
        });
        this.route('billing', function () {
          this.route('payment-info');
          this.route('invoices');
        });
      });
    });
  });

  exports.default = Router;
});