define('fund-figure-website/controllers/home/pricing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    stepOne: true,
    stepTwo: false,
    municipalities: Ember.computed('', {
      get() {
        return Ember.get(this, 'store').findAll('municipalities');
      }
    }),
    costByPop: Ember.computed('selectedMunicipality', function () {
      let _this = this,
          municipality = this.get('selectedMunicipality'),
          population = Number(municipality.get('population').replace(/,/g, '')),
          yearly = population,
          monthly = Math.round(yearly / 10, 0),
          stripeMonthlyDesc = '$' + monthly + ' monthly',
          stripeYearlyDesc = '$' + yearly + ' yearly';
      _this.setProperties({ monthlyCost: monthly, yearlyCost: yearly, stripeMonthlyDesc: stripeMonthlyDesc, stripeYearlyDesc: stripeYearlyDesc });
      return true;
    }),
    actions: {
      selectedMunicipalityChanged(municipality, powerObject) {
        Ember.set(this, 'selectedMunicipality', municipality);
        Ember.set(this, 'stepTwo', true);
        Ember.set(this, 'stepOne', false);
      },
      saveTransactions(municipality) {
        this.get('selectedMunicipality').save().then(res => {
          Ember.set(this, 'stepThree', true);
          Ember.set(this, 'stepTwo', false);
        });
      }
    }
  });
});