define('fund-figure-website/components/helper/still-creating-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement() {
      let svgElement = document.getElementById('mySVG'),
          go = SVG.adopt(svgElement),
          pinkGear = go.select('.pink-gear'),
          blueGreenGear = go.select('.blue-green-gear'),
          yellowGear = go.select('.yellow-gear'),
          greenGear = go.select('.green-gear');

      pinkGear.animate(2000, '<>', 0).rotate(45).loop(1, true).loop();
      blueGreenGear.animate(2000, '<>', 0).rotate(-120).loop(1, true).loop();
      yellowGear.animate(2000, '<>', 0).rotate(60).loop(1, true).loop();
      greenGear.animate(2000, '<>', 0).rotate(-220).loop(1, true).loop();
    }
  });
});