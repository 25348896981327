define('fund-figure-website/components/d3/visual-bubbles', ['exports', 'd3-selection', 'd3-scale', 'd3-force', 'd3-drag', 'd3-array', 'd3-scale-chromatic'], function (exports, _d3Selection, _d3Scale, _d3Force, _d3Drag, _d3Array, _d3ScaleChromatic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['visual-bubbles'],
    width: null,
    height: null,
    colorScheme: null,

    drawChart: Ember.computed('data', function () {
      let data = this.get('data'),
          radius = 1,
          colorScheme = this.get('colorScheme'),
          _this = this;
      if (data === undefined || data === null) {
        console.log('no data');
        return 0;
      }
      let store = this.get('store'),
          cfg = {
        marginChart: {
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        }
      },
          parentWidth = this.get('width') || this.$().parent().width() || 400,
          parentHeight = this.get('height') || this.$().parent().height() || 400,
          width = parentWidth - cfg.marginChart.left - cfg.marginChart.right,
          height = parentHeight - cfg.marginChart.top - cfg.marginChart.bottom,
          z = (0, _d3Scale.scaleOrdinal)(colorScheme || _d3ScaleChromatic.schemeDark2),

      // pack = pack()
      // .size([width, height])
      // .padding(1.5),
      svg = (0, _d3Selection.select)(this.element);
      svg.select('g').remove();
      z.domain(data.map(c => {
        c.get('stateName');
      }));
      console.log('drawing graph links');
      console.log(data);
      svg.attr('width', width).attr('height', height);

      let scaleRadius = (0, _d3Scale.scaleLinear)().domain([(0, _d3Array.min)(data, function (d) {
        console.log('reseting scale');return +(d.get('budget') || '0' || '0').replace(/,/g, '');
      }), (0, _d3Array.max)(data, function (d) {
        return +(d.get('budget') || '0').replace(/,/g, '');
      })]).range([50, 100]);

      let force = (0, _d3Force.forceCollide)().radius(d => scaleRadius(+(d.get('budget') || '0').replace(/,/g, ''))).iterations(1);

      let simulation = (0, _d3Force.forceSimulation)(data).force('charge', (0, _d3Force.forceManyBody)()).force('collide', force).force('x', (0, _d3Force.forceX)(width / 2)).force('y', (0, _d3Force.forceY)(height / 2)).on('tick', tick);

      let tooltip = (0, _d3Selection.select)("body").append("div").classed('well-white radius padding-20', true).style("position", "absolute").style("z-index", "9999999").style("visibility", "hidden").style("top", 0).style("left", 0);

      let g = svg.append("g"),
          background = g.append("g").append('rect').attr('width', width).attr('height', height).attr('opacity', 0).on('mousedown', mousedownCanvas),
          circle = g.append("g").selectAll(".circle"),
          AccountTitle = g.append("g").attr("class", "texts").selectAll("text"),
          accountBudget = g.append("g").attr("class", "texts").selectAll("text");

      restart();

      function restart() {
        console.log(data);

        circle = circle.data(data);
        circle.exit().remove();
        circle = circle.enter().append("circle").style("fill", d => z(d.get('stateName'))).attr("r", d => {
          return scaleRadius(+(d.get('budget') || '0').replace(/,/g, ''));
        }).attr('stroke', "#e8eaea").attr('stroke-width', 4).merge(circle).on('click', selectNode).call((0, _d3Drag.drag)().on("start", dragstarted).on("drag", dragged).on("end", dragended));
        // .on('mouseenter', hover)        
        // .on('mouseleave', noHover) 

        AccountTitle = AccountTitle.data(data);
        AccountTitle.exit().remove();
        AccountTitle = AccountTitle.enter().append("text").attr("font-size", d => scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')) / 5.5).attr("dx", 0)
        // .attr("dy", d => -scaleRadius((d.get('budget') || '0')) / 5)
        .attr("dy", 0).style('text-anchor', 'middle').attr('radius', d => scaleRadius(+(d.get('budget') || '0').replace(/,/g, ''))).text(d => d.get('nickname') || d.get('stateName')).call(wrap)
        // .text(function(d){
        //  return wrap(d.get('stateName'), scaleRadius((d.get('budget') || '0')), this)
        // })
        .merge(AccountTitle);

        accountBudget = accountBudget.data(data);
        accountBudget.exit().remove();
        accountBudget = accountBudget.enter().append("text").attr("font-size", d => scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')) / 3.5).style("font-weight", "700").style('fill', "whitesmoke").attr("dx", 0).attr("dy", d => -scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')) / 3).style('text-anchor', 'middle').text(function (d, element) {
          // return getName(d, this);
          return Number((d.get('budget') || '0').replace(/,/g, '')).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }).merge(accountBudget);

        // Update and restart the simulation.
        simulation.nodes(data);
        // simulation.force("link").links(links);
        simulation.alpha(1).restart();
      }

      function wrap(text) {
        text.each(function () {
          var text = (0, _d3Selection.select)(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              width = text.attr('radius'),
              lineNumber = 0,
              lineHeight = 1,
              // ems
          y = text.attr("y"),
              x = text.attr('x'),
              dy = parseFloat(text.attr("dy")),
              tspan = text.text(null).append("tspan").attr('x', x).attr("dy", dy + "em");
          while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text.append("tspan").attr('x', x).attr("dy", `${lineHeight + dy}em`).text(word);
            }
          }
        });
      }

      function tick() {
        circle.attr('cx', function (d) {
          return d.x = Math.max(scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')) + 4, Math.min(width - scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')) - 4, d.x));
        }).attr('cy', function (d) {
          return d.y = Math.max(scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')) + 4, Math.min(height - scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')) - 4, d.y));
        });
        AccountTitle.attr('x', function (d) {
          return d.x = Math.max(scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')), Math.min(width - scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')), d.x));
        }).attr('y', function (d) {
          return d.y = Math.max(scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')), Math.min(height - scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')), d.y));
        }).selectAll('tspan').attr('x', function (d) {
          return d.x = Math.max(scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')), Math.min(width - scaleRadius(+(d.get('budget') || '0').replace(/,/g, '')), d.x));
        });
        accountBudget.attr('x', function (d) {
          return d.x = Math.max(radius, Math.min(width - radius, d.x));
        }).attr('y', function (d) {
          return d.y = Math.max(radius, Math.min(height - radius, d.y));
        });
      }

      function hover(d) {
        if (_d3Selection.event.defaultPrevented) return;
        (0, _d3Selection.select)(this).attr('stroke', '#e54e43').text(d => 'hey there');
        tooltip.style("visibility", "visible").style("top", `${d.y + 50}px`).style("left", `${d.x + 50}px`).text(Number(d.get('budget') || '0').toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
      }

      function noHover(d) {
        if (_d3Selection.event.defaultPrevented) return;
        (0, _d3Selection.select)(this).attr('stroke', '#e8eaea');
        tooltip.style("visibility", "hidden");
      }

      function dragstarted(d) {
        (0, _d3Selection.select)(this).classed("active", true);
      }

      function dragged(d) {
        (0, _d3Selection.select)(this).attr("cx", d.x = _d3Selection.event.x).attr("cy", d.y = _d3Selection.event.y).attr('x', _d3Selection.event.x);
      }

      function dragended(d) {
        // select(this).classed("active", false);
        simulation.alpha(1).restart();
      }

      function mousedownCanvas(d, i) {
        _this.canvasClick(d);
        // restart();
      }

      function selectNode(d, i) {
        if (_d3Selection.event.defaultPrevented) return;
        let objects = d.toJSON().subAccounts;
        console.log(objects);
        if (objects.length > 1) {
          data.clear();
          restart();
          d.get('subAccounts').forEach(sub => {
            if (objects.length < 10) {
              data.push(sub);
            } else {
              if (+sub.get('budget').replace(/,/g, "") > 5000) {
                data.push(sub);
              } else {
                var index = objects.indexOf(sub.id); // <-- Not supported in <IE9
                if (index !== -1) {
                  console.log('removed', objects);
                  objects.splice(index, 1);
                }
              }
            }
          });
          if (data.length == objects.length) {
            console.log('restart');
            scaleRadius = (0, _d3Scale.scaleLinear)().domain([(0, _d3Array.min)(data, function (d) {
              console.log('reseting scale');return +(d.get('budget') || '0').replace(/,/g, '');
            }), (0, _d3Array.max)(data, function (d) {
              return +(d.get('budget') || '0').replace(/,/g, '');
            })]).range([75, 125]);
            // data.push(d);
            // _this.set('data', subArray);
            restart();
          }
        } else {
          return;
        }
      }

      function mousedownNode(d, i) {
        if (i == 0) {
          return;
        }
        nodes.splice(i, 1);
        links = links.filter(function (l) {
          return l.source !== d && l.target !== d;
        });
        userNetwork.get('supporters').removeObject(d.model);
        userNetwork.save().then(() => {
          d.get('avatar').then(res => {
            return res.destroyRecord();
          }).then(() => {
            return d.destroyRecord();
          }).catch(err => {
            console.log(err);
          });
        }).then(() => {
          restart();
        }).catch(err => {
          console.log(err);
        });
        _d3Selection.event.stopPropagation();
      }
    })
  });
});