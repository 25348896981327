define('fund-figure-website/components/helper/nav-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mouseEnter() {
      Ember.set(this, 'mouse_enter', true);
    },
    mouseLeave() {
      Ember.set(this, 'mouse_enter', false);
    }
  });
});